import {Component, ElementRef, HostListener, NgZone, Renderer2, ViewChild} from '@angular/core';
import {IonRouterOutlet, MenuController, PopoverController} from '@ionic/angular';
import {GlobalService} from './services/global.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {EventService} from './services/event.service';
import {FeedbackService} from './services/feedback.service';
import {UserIdleTimerService} from './services/userIdle.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    @ViewChild('globalFileUpload', {static: true}) globalFileUpload: ElementRef;
    @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;

    public appPages = [];
    public otherPages = [];
    public showSidebar = true;
    constructor(
        public eventBus: EventService,
        public global: GlobalService,
        private renderer: Renderer2,
        private zone: NgZone,
        public popoverCtrl: PopoverController,
        private router: Router,
        public jwtHelper: JwtHelperService,
        private menuCtrl: MenuController,
        private readonly feedbackService: FeedbackService,
        private userIdle: UserIdleTimerService
    ) {
        this.menuCtrl.enable(false);
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.updateSidebarVisibility(event.urlAfterRedirects);
        });
    }

    ngOnInit() {
        this.global.routerOutlet = this.routerOutlet;
        this.global.globalFileUploadInput = this.globalFileUpload;
        this.global.renderer = this.renderer;
        this.eventBus.getEvent('AppInitatedSet').subscribe((event) => {
            this.global.trackTabs();
            this.addIcons();
            this.startIdleWatching();
        });
    }

    private updateSidebarVisibility(url: string) {
        this.showSidebar = url !== '/not-found';
    }

    private startIdleWatching() {
        if (!this.global.isWatching) {
            this.userIdle.startWatching();
        }
    }
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        const tabs = +localStorage.getItem('tabs') - 1;
        localStorage.setItem('tabs', tabs.toString());
    }

    addIcons() {
        this.appPages = [];
        if (this.global.loggedInUser['userRole'] === 'superAccountant') {
            this.appPages.push({
                title: 'Reports',
                url: 'reports',
                pathContains: 'reports',
                icon: ['fas', 'file'],
            });
            this.otherPages = [];
            // this.otherPages.push({ title: 'Settings', url: 'settings', icon: ['fas','cog'] });
            this.otherPages.push({title: 'Logout', url: null, icon: ['fas', 'sign-out-alt']});
            return;
        }
        this.appPages.push({title: 'Home', url: 'dashboard', icon: ['fas', 'home']});
        this.appPages.push({
            title: 'Processing',
            pathContains: 'processing',
            url: 'processing/batches',
            icon: ['fas', 'magic'],
        });
        this.appPages.push({title: 'Transactions', url: 'transactions', icon: 'money-check'});
        this.appPages.push({title: 'Tenant Transactions', url: 'tenant/transactions', icon: 'money-check'});
        this.appPages.push({
            title: 'Customers',
            url: 'companies',
            pathContains: 'companies',
            icon: ['far', 'building'],
        });
        this.appPages.push({title: 'Bank Account', url: 'bank-accounts', icon: 'university'});
        this.appPages.push({title: 'Admins', url: 'admins', icon: ['fas', 'user-tie']});
        // this.appPages.push({ title: 'Transactions', url: 'transactions', icon: 'money-check'});
        // if (this.global.loggedInUser['userRole'] === 'superAdmin') {
        // 	this.appPages.push({ title: 'Billing', url: 'billing', icon: ['fas','money-bill'] });
        // }
        this.appPages.push({title: 'Landlord KYC', url: 'kyc/list', pathContains: 'kyc', icon: ['fas', 'info-circle']});
        this.appPages.push({
            title: 'Tenants',
            url: 'tenants/list',
            pathContains: '',
            icon: ['fas', 'users'],
        });
        this.appPages.push({title: 'Alerts', url: 'alerts', icon: ['fas', 'exclamation-triangle']});
        if (this.global.loggedInUser['userRole'] === 'superAdmin') {
            this.appPages.push({title: 'KPI', url: 'kpi/customers', pathContains: 'kpi', icon: ['fas', 'chart-line']});
            this.appPages.push({
                title: 'Reports',
                url: 'reports',
                pathContains: 'reports',
                icon: ['fas', 'file'],
            });
        }
        this.appPages.push({title: 'STR Filings', url: 'str-filings', icon: 'money-check'});
        this.otherPages = [];
        // this.otherPages.push({ title: 'Settings', url: 'settings', icon: ['fas','cog'] });
        this.otherPages.push({title: 'Logout', url: null, icon: ['fas', 'sign-out-alt']});
    }

    actionOnBtn(page) {
        if (page['url'] === null) {
            if (page['title'] === 'Logout') {
                this.global.logoutUser();
                this.otherPages = [];
                this.appPages = [];
            }
        } else {
            this.global.navCtrl.navigateRoot(page['url']);
        }
    }

    activeRoute(page) {
        if (page.pathContains) {
            return this.router.url.indexOf(page.pathContains) > -1 ? true : false;
        } else {
            return this.router.url === '/' + page.url;
        }
    }
    activeSideItem(url) {
        if (this.router.url.indexOf(url) > -1) {
            return true;
        } else {
            return false;
        }
    }
}
